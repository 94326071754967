import { Button, ExcelColorIcon, Flex, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import styled from "styled-components";
import SliderTabs from "../../../components/shared/SliderTabs";
import DynamicTabContent from "./DynamicTabContent";
import { useMutation, useQuery } from "react-query";
import { _getCollaboratorByAgence } from "../api";
import { generate_tabs_onboarding, TABVIEW } from "../constant";
import { _api_download_file } from "../../../utils/fetcher";
import { BASE_URL_API } from "../../../constants";
import ExportOnboarding from "../services/ExportOnboarding/ExportOnboarding";

export default function Content({
    AgencyId,
    userIdSelected,
    setUserIdSelected,
}) {
    // state pour le changement de STATUS "onboarding, en cours etc.§.."
    const [status_slider_tab, setStatusSliderTab] = useState(0);

    const { data } = useQuery(
        ["getCollaboratorByAgence", AgencyId],
        () => _getCollaboratorByAgence(AgencyId),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        }
    );

    const { tabs: TABS_ONBOARDING } = generate_tabs_onboarding(data);

    const TABS_ARRAY = [
        TABS_ONBOARDING.waiting_preonboarding,
        TABS_ONBOARDING.preonboarding,
        TABS_ONBOARDING.onboarding,
        TABS_ONBOARDING.end_onboarding,
        TABS_ONBOARDING.suivi,
    ];

    return (
        <Main column>
            <Flex space="between">
                <SliderTabs
                    tabs={TABS_ARRAY.map((tab, index) => {
                        const { label, description } = tab;
                        // const description = null;
                        return (
                            <Flex
                                key={index}
                                styles={{
                                    gap: "8px",
                                    // border: "3px dashed blue",
                                    paddingInline: "16px",
                                    width: "100%",
                                }}
                                vAlign="center"
                            >
                                <span
                                    style={{
                                        display: "inline-block",
                                        // border: "3px dashed green",
                                        whiteSpace: "nowrap",
                                    }}
                                    className="component"
                                >
                                    {label}
                                </span>
                                {description !== null && (
                                    <SliderTabBadge
                                        color={description}
                                        content={description}
                                    />
                                )}
                            </Flex>
                        );
                    })}
                    defaultActiveIndex={status_slider_tab}
                    onChange={(tab) => {
                        console.log({ tab });
                        setUserIdSelected(null);
                        setStatusSliderTab(tab);
                    }}
                />
                {/* export file feature */}
                <ExportOnboarding agency_id={AgencyId} />
            </Flex>

            <DynamicTabContent
                userIdSelected={userIdSelected}
                AgencyId={AgencyId}
                TabView={TABVIEW}
                users={data}
                activeTab={status_slider_tab}
                setActiveTab={setStatusSliderTab}
            />
        </Main>
    );
}

const Main = styled(Flex)`
    background-color: #fff;
    padding: 24px 16px;
    height: 591px;

    box-shadow: 0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.07),
        0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.11);
`;

const SliderTabBadge = styled(Text)`
    /* background-color: #ffb900; */
    background-color: ${({ color }) => (color === 0 ? "#d1D1D1" : "#ffb900")};
    /* color: white; */
    color: ${({ color }) => (color === 0 ? "white" : "white")};
    width: 24px;
    height: 24px;
    pointer-events: none;
    /* margin-left: 30%; */
    border-radius: 50%;
    padding: 6px 12px;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
