import React, { useEffect, useMemo } from "react";
import { Container, ContainerFluid } from "../../../common/styles";

import styled from "styled-components";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import {
    BuildingBank16Filled,
    PeopleTeam16Filled,
} from "@fluentui/react-icons";
import StatusBadge from "./StatusBadge";
import HeaderDates from "./HeaderDates";
import FooterActions from "./FooterActions";
import CollaboratorTasks from "./CollaboratorTasks";
import HeaderUserDetail from "./commun/HeaderUserDetail";
import { useQuery } from "react-query";
import { _getUserDetail } from "../api";
import { min } from "moment";
import useToast from "../../../hooks/useToast";
import Toast from "../../../components/Alerts";

export default function DetailCollaborator() {
    const location = useLocation();
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const searchParamsType = searchParams.get("type");
    const [toast, setToast] = useToast();

    useEffect(() => {
        if (searchParamsType) {
            setToast({
                visible: true,
                level:
                    searchParamsType === "delete_task_success"
                        ? "success"
                        : "danger",
                content:
                    searchParamsType === "delete_task_success"
                        ? "La tâche a bien été supprimée"
                        : "",
            });
        }
    }, []);

    const user = location.state;
    const activeTab = user?.activeTab;

    const user_id = params?.id_task;

    const {
        isLoading: isLoadingUserDetail,
        data: dataUserDetail,
        refetch: refetchDataUserDetail,
    } = useQuery(["userdetail", user_id], () => _getUserDetail(user_id), {
        refetchOnWindowFocus: false,
    });

    return (
        <ContainerFluid
            fill
            styles={{ backgroundColor: "#F5F5F5", minHeight: "100vh" }}
            gap="gap.medium"
            column
        >
            <Toast
                {...toast}
                onStatusChange={() => {
                    setToast({
                        visible: false,
                    });
                    setSearchParams(new URLSearchParams());
                }}
            />
            <ContainerStyled space="between">
                <HeaderUserDetail />
            </ContainerStyled>
            <CollaboratorTasks
                refetchDataUserDetail={refetchDataUserDetail}
                activeTab={activeTab}
                dataUserDetail={dataUserDetail}
            />
        </ContainerFluid>
    );
}

const ContainerStyled = styled(Container)`
    /* margin-top: 24px; */
    /* margin-bottom: 48px; */
`;
