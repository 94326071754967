import { ROUTES } from ".";
import {
    CalendarLtr24Regular,
    Document24Regular,
    HomePerson24Regular,
    TaskListLtr24Regular,
    Clock24Regular,
    Payment24Regular,
    BuildingMultiple24Regular,
    Home24Regular,
    Handshake24Regular,
} from "@fluentui/react-icons";
import { useAuthDetailStore } from "../../stores/useAuthStore";
import { ROLES_CODE } from "../roles";

const detail = useAuthDetailStore.getState()?.detail;

export const BREADCRUMB_STRUCTURE = [
    {
        id: 1,
        name: "Accueil",
        link: ROUTES.home,
        icon: <HomePerson24Regular />,
        activated: true,
    },
    {
        id: 2,
        name: "Mes tâches",
        link: ROUTES.task_manager,
        icon: <TaskListLtr24Regular />,
        activated: true,
    },
    {
        id: 3,
        name: "Absences",
        link: ROUTES.leave,
        icon: <CalendarLtr24Regular />,
        activated: true,
    },
    {
        id: 4,
        name: `Suivi du temps`,
        link: ROUTES.time_reporting.home,
        icon: <Clock24Regular />,
        activated:
            detail?.features?.time_reporting?.display_in_main_menu ?? false,
    },
    {
        id: 8,
        name: `Télétravail`,
        link: ROUTES.remote_job.home,
        icon: <Home24Regular />,
        activated: true,
    },
    {
        id: 5,
        name: "Notes de frais",
        link: ROUTES.expenses.home,
        icon: <Payment24Regular />,
        activated: true,
    },

    {
        id: 6,
        name: "Documents RH",
        link: ROUTES.rh_document,
        icon: <Document24Regular />,
        activated: true,
    },
    {
        id: 7,
        name: "Mon établissement",
        link: ROUTES.annuaire,
        icon: <BuildingMultiple24Regular />,
        activated: true,
    },
];

export const generate_humberger_global = () => {
    const humberger = [...BREADCRUMB_STRUCTURE];

    const detail = useAuthDetailStore.getState()?.detail;
    const isRH = detail?.roles.some((role) => role?.code === ROLES_CODE.hr);

    if (isRH) {
        humberger.push({
            id: 8,
            name: "Onboarding",
            link: ROUTES.onboarding.home,
            icon: <Handshake24Regular />,
            activated: true,
        });
    }
    return humberger;
};

export const BREADCRUMB_HUMBERGER_GLOBAL = generate_humberger_global();
