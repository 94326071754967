import React, { useEffect, useState } from "react";
import { Text } from "@fluentui/react-components";
import {
    Flex,
    Table,
    tableHeaderCellBehavior,
} from "@fluentui/react-northstar";
import {
    ArrowSortDown16Filled,
    ArrowSortUp16Filled,
} from "@fluentui/react-icons";
import ModalDetailTask from "./ModalDetailTask";
import TaskRow from "./TaskRow";
import {
    _api_validate_document,
    _get_collaborator_tasks,
    _update_status_task,
} from "../../api/tasks";
import { DialogStyle, TableLabel } from "./style";
import { useQuery } from "react-query";
import LoaderPage from "../../components/loaders/LoaderPage";
import ModalDetailTaskWithoutActions from "./ModalDetailTaskWithoutActions";
import {
    BADGE_STATUS_TASKS,
    SORT_BY_STATUS,
    TASK_STATUS,
} from "../../constants/tasks";
import Toast from "../../components/Alerts";
import DialogDetailTask from "./DialogDetailTask";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material";
import { DEVICE } from "../../constants/responsive";

const ArrowSortDown = (
    <ArrowSortDown16Filled style={{ color: "var(--color-primary)" }} />
);
const ArrowSortUp = (
    <ArrowSortUp16Filled style={{ color: "var(--color-primary)" }} />
);

const ViewTable = ({ tasksProps = null, isPageOnboarding = false }) => {
    const location = useLocation();

    const [toast, setToast] = useState({
        visible: false,
        content: "",
        level: "success",
    });

    useEffect(() => {
        setToast({
            visible: Object.keys(location.state ?? []).length,
            content: location.state?.message,
            level: "success",
        });
    }, []);

    const [sorted, setSorted] = useState({
        due_date: 0, // 0 asc - 1 desc
        status: 0,
    });

    const [tasks, setTasks] = useState(null);

    useEffect(() => {
        setTasks(tasksProps);
    }, [tasksProps]);

    const {
        isLoading,
        isFetching,
        refetch: refetchGetAllTasksForTable,
    } = useQuery(["getAllTasksForTable"], () => _get_collaborator_tasks(), {
        refetchOnWindowFocus: false,
        enabled: !tasksProps,
        onSuccess: (data) => {
            return setTasks(data);
        },
    });

    const _sort_by_due_date = () => {
        if (!sorted.due_date) {
            const sorted_tasks = tasks?.tasks?.sort((a, b) => {
                return moment(a.due_date) - moment(b.due_date);
            });
            setTasks((state) => ({
                ...state,
                tasks: sorted_tasks,
            }));
            setSorted((state) => ({
                ...state,
                due_date: 1,
            }));
        } else {
            const sorted_tasks = tasks?.tasks?.sort(
                (a, b) => moment(b.due_date) - moment(a.due_date)
            );
            setTasks((state) => ({
                ...state,
                tasks: sorted_tasks,
            }));
            setSorted((state) => ({
                ...state,
                due_date: 0,
            }));
        }
        return;
    };

    const _sort_by_status = () => {
        if (!sorted?.status) {
            const sorted_tasks = tasks?.tasks?.sort(
                (a, b) => SORT_BY_STATUS[a.status] - SORT_BY_STATUS[b.status]
            );
            setTasks((state) => ({
                ...state,
                tasks: sorted_tasks,
            }));
            setSorted((state) => ({
                ...state,
                status: 1,
            }));
        } else {
            const sorted_tasks = tasks?.tasks?.sort(
                (a, b) => SORT_BY_STATUS[b.status] - SORT_BY_STATUS[a.status]
            );
            setTasks((state) => ({
                ...state,
                tasks: sorted_tasks,
            }));
            setSorted((state) => ({
                ...state,
                status: 0,
            }));
        }
        return;
    };

    return (
        <>
            <Toast
                key="Task_toast"
                visible={toast.visible}
                content={toast.content}
                level={toast.level}
                onStatusChange={(e) => {
                    location.state = {};
                    return setToast({
                        visible: false,
                        content: "",
                        level: "warning",
                    });
                }}
            />
            {isLoading || isFetching ? (
                <LoaderPage label="Chargement des tâches" />
            ) : (
                <TableStyle aria-label="table" styles={{}}>
                    {/* Head */}
                    <Table.Row header>
                        <Table.Cell
                            content={
                                <Flex
                                    onClick={_sort_by_due_date}
                                    gap="gap.smaller"
                                    vAlign="center"
                                    styles={{ cursor: "pointer" }}
                                >
                                    <TableLabel weight="regular">
                                        Échéance
                                    </TableLabel>
                                    {!sorted.due_date
                                        ? ArrowSortDown
                                        : ArrowSortUp}
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "126px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex
                                    gap="gap.smaller"
                                    vAlign="center"
                                    styles={{ cursor: "pointer" }}
                                    onClick={_sort_by_status}
                                >
                                    <Text weight="regular">Statut</Text>
                                    {!sorted.status
                                        ? ArrowSortDown
                                        : ArrowSortUp}
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "106px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text weight="regular">À faire</Text>
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "160px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <TableLabel
                                        bolder
                                        content="Nom de la tâche"
                                    />
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "551px" }}
                        />

                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text weight="regular">Catégorie</Text>
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "171px" }}
                        />
                        <Table.Cell
                            content={
                                <Flex gap="gap.smaller" vAlign="center">
                                    <Text weight="regular">Créée par</Text>
                                </Flex>
                            }
                            accessibility={tableHeaderCellBehavior}
                            style={{ maxWidth: "200px" }}
                        />
                    </Table.Row>
                    {/* tasks row */}

                    {tasks?.tasks?.map((el) => {
                        return (
                            <>
                                <Link
                                    to={`/dashboard/tasks/${el?.id}/user/${el?.assigned_to_user?.id}/detail`}
                                    state={{
                                        referrer: isPageOnboarding
                                            ? location.pathname
                                            : "/dashboard/tasks",
                                        isHrPage: isPageOnboarding,
                                    }}
                                    style={{
                                        textDecoration: "none",
                                    }}
                                >
                                    <TaskRow data={el} />
                                </Link>
                            </>
                        );
                    })}
                </TableStyle>
            )}
        </>
    );
};

export default ViewTable;

const TableStyle = styled(Table)`
    width: 1410px;
    margin-top: 11px;

    padding-block: 8px;

    @media ${DEVICE.mobileS} {
        margin-left: 10px;
        padding-inline: 12px;
    }
    @media ${DEVICE.tablet} {
        margin-left: 30px;
        padding-inline: 32px;
    }
`;
